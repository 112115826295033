import axios from 'axios';

export interface AmpValidatorPayload {
  insertToHead: string;
  insertToBody: string;
  publisherId: string;
  infiniteScrollExists: boolean;
}
export interface AmpValidatorResponse {
  statusTxt: string;
  errorMsg?: string;
  testedHtml?: string;
}
export async function validateCode(payload: AmpValidatorPayload) {
  const { data: responseData } = await axios.post(`/api/v1/amp-validator`, payload);
  return responseData;
}
